import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const DataJabatanService = {
  getAllData,
}

function getAllData(data){
  let sk_id = data.satuan_kerja_id ? data.satuan_kerja_id : 6
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'/admin/jabatan/get-data',
    method:'post',
    data:{
    nip:current.username,
    "page": data.page ? data.page : 1,
    "perpage": data.row_per_page,
    "search": data.search,
    "params": data.params,
    "order": data.order,
    "params_satuan_kerja_id": sk_id,
    // "params_sorts": data.params_sorts,
    }
  })
}

export default DataJabatanService