<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <h3 class="display-2">Data Jabatan</h3>
          </template>
          <v-row>
            <v-col cols="6">
              <v-autocomplete v-model="selectSKPD" :items="skpd" label="SKPD" item-text="v" item-value="k" hide-details></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="search" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[10,15,20]}"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.jabatan_id="{ item }">
              <br>
              <div>
                <b> PLT-{{item.jabatan_id}} </b>
              </div>
              <br>
            </template>
            <template v-slot:item.status="{ item }">
              <a @click="detailJabatan(item)">{{item.status == 1 ? 'Jabatan Sudah Diisi Oleh Pegawai' : 'Jabatan Kosong' }}</a>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDetail" max-width="900px">
      <v-card>
        <v-card-title>
          <h2>Detail {{item.jabatan_nama}}</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-simple-table class="table table-bordered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>NIP</th>
                    <th>Nama</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(peg, index) in item.pegawai" v-bind:key="index">
                    <td><h3>{{ index + 1 }}</h3></td>
                    <td><h3>{{ peg.peg_nip }}</h3></td>
                    <td><b><h3>{{ peg.peg_nama }}</h3></b></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="yellow darken-1" @click="dialogDetail = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DataJabatanService from '@/services/DataJabatanService'
import store from '@/store'
import _g from '../../global'
import axios from 'axios'
import _ from 'lodash'
export default {
  data(){
    return{
      jabatanKosong:true,
      forceUpdate:false,
      loading:false,
      detailData:{},
      skpd:[],
      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Jabatan', value:'jabatan_nama', sortable:false, class:'header-index-profile'},
        {text:'ID PLT', value:'jabatan_id', sortable:false, class:'header-index-profile'},
        {text:'Status', value:'status', sortable:false, class:'header-index-profile'},
      ],
      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
      selectSKPD:'',
      search: '',
      tableValue:{},
      totalKeseluruhan:0,
      rowPerPage:10,
      page:1,
      dialogDetail: false,
      item:{},
      dialogPegawaiReviewer: false,
      dataPegawai: {}
    }
  },

  watch:{
    page(val){
      this.tableValue.page = val
      this.updateTable(this.tableValue)
    }
  },

  mounted(){
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD, vm.selectBulan, vm.jabatanKosong], val => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, satuan_kerja_id:this.selectSKPD, search:val[1], page:this.page, params_sorts:this.jabatanKosong})
    })
  },

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
    this.getListSKPD()
  },

  computed:{
    jabatanTidakKosong:{
      get:function(){
        return !this.jabatanKosong
      },
      set:function(val){
        this.jabatanKosong = !val
      }
    }
  },

  methods:{
    // getListSKPD(){
    //   var url = "v1/get-data-skpd-satuan-kerja"
    //   let current = store.state.user.current
    //   let user = store.state.user
    //   if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    //     current.username = _g.overrideWithPLT(user.current.role)
    //   }else{
    //     current.username = user.current.username
    //   }
    //   var base_url_api = _g.getBaseURLAPIERK(current.year)
    //   const url_ = base_url_api + url
    //   axios.post(url_).then(response => {
    //     let data = []
    //     _.each(response.data.data, function(value, key, obj){
    //       data.push({k:key, v: value})
    //     })
    //     this.skpd = data
    //   }).catch(err => {
    //     var error = err.message
    //     store.commit('snackbar/setSnack',{message: error, color:'error'})
    //   })
    // },

    updateTable(val){
      this.loading = true
      this.tableValue = val ? val: this.tableValue
      this.items = []
      DataJabatanService.getAllData({
        search:this.tableValue.search,
        row_per_page:this.tableValue.itemsPerPage,
        page:this.tableValue.page,
        satuan_kerja_id:this.selectSKPD})
      .then((response)=>{
        this.items = response.data.data
        this.totalKeseluruhan = response.data.total_keseluruhan
        console.log(this.items)
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    },

    detailJabatan(item){
      this.item = item
      this.dialogDetail = true
    },

    getListSKPD(){
      var url = "v1/get-data-skpd2"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },
  },
}
</script>

<style lang="css" scoped>
</style>
